<template>
    <v-container persistent fluid>
        <v-footer tile style="font-family: Georgia">
            <v-row class="mt-n1 mb-1">
                <v-btn 
                rounded 
                small 
                class="orange black--text mr-5"
                @click="loadSubconrequest()">すべての依頼
                    <v-badge
                    :content="allRequest.length"
                    v-if="allRequest.length && !receiveNotification" 
                    color="red"
                    style="margin-left:5%">
                    </v-badge> 
                </v-btn>
                <!-- ALL REQUEST -->
                <v-btn 
                rounded 
                small 
                class="orange black--text mr-5"
                @click="NotifFornewSubconRequest()">依頼前
                    <v-badge
                    :content="newRequest.length"
                    v-if="newRequest.length" 
                    color="red"
                    style="margin-left:5%">
                    </v-badge> 
                </v-btn>
                <!-- RECEIVE REQUEST -->
                <v-btn 
                rounded 
                small 
                class="orange black--text"
                @click="NotifForOngoingRequest()">依頼済み
                    <v-badge
                    :content="ongoingReq.length"
                    v-if="ongoingReq.length"
                    color="red"
                    style="margin-left:5%">
                    </v-badge> 
                </v-btn>
                <!-- SENT REQUEST -->
                <v-spacer></v-spacer>
                <!-- /Search Code number -->
            <v-text-field 
            label="検索" 
            v-model="searchFirstRequest" 
            dense 
            small 
            outlined
            clearable  
            width="100"></v-text-field>
            </v-row>
        </v-footer>
        <div style="text-align:center;font-size:40px">{{ nameOfSubcon ? nameOfSubcon : ''  }}</div>

<!-- FIRST REQUEST -->
        <v-toolbar dark dense ><span style="font-size:30px;" >物件リスト </span>
       
        </v-toolbar>

<!-- //NOTE DATA TABLE FOR FIRST REQ -->
        <v-data-table
            :headers="firstRequestHeaders"
            :items="allRequest"
            height="60vh"
            fixed-header
            :search="searchFirstRequest"
            class="elevation-5"
            :items-per-page="itemsPerPage"
        >
<!-- column for code number -->
            <template v-slot:[`item.code_number`]="{item}">
                <v-tooltip top>
                    <template  v-slot:activator="{ on }" >
                        <v-layout>
                           
                                <v-card
                                flat
                                color="transparent"
                                v-on="on"
                                @click="viewProposal(item)"><span style="color:green;font-weight:bold">{{item.code_number}}</span>
                                </v-card>
                                <span style="color:red;font-size:12px;font-weight:bold" class="blinking"  v-if="urgentcodeNumber.includes(item.code_number) && !item.isOverDue"> 至急</span>
                                
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                    
                                            <v-icon
                                            style="margin-left:25px" 
                                            color="error"
                                            v-on="on"
                                            size="25"  
                                            @click="sendNoRequest(item)"
                                            v-if="item.order_status != 'No Request' && !item.order_status">mdi-note-remove-outline
                                            </v-icon>
                                    
                                    </template>依頼不用 ?
                                </v-tooltip>
                                <!-- urgent -->
                        </v-layout>
                    </template>提案を表示    
                </v-tooltip>  
            </template>

            <template v-slot:[`item.joutou`]="{item}"><span><b>{{item.joutou}}</b></span></template>
            <template v-slot:[`item.lessjoutouDate`]="{item}"><span style="color:red"><b>{{item.lessjoutouDate}}</b></span></template>
            <template v-slot:[`item.firstReq_with_changes`]="{item}"><span style="color:red"><b>{{item.firstReq_with_changes}}</b></span></template>
            <template v-slot:[`item.firstReq_without_changes`]="{item}"><span style="color:rgb(2, 150, 0)"><b>{{item.firstReq_without_changes}}</b></span></template>

            <template v-if="account.role == 'Subcon User'" v-slot:[`item.order_status`]="{item}">
                <span >
                    {{ getOrderStatus(item) }}
                </span>
            </template>
            <template v-else v-slot:[`item.order_status`]="{item}"><span >{{item.order_status}}</span></template>
        

        </v-data-table>     

        <div>
            <editDialogVue></editDialogVue>
        </div>
        <div>
            <RequestFormVue
            
            :forSubconReq = "forSubconReq"
            :dataFromLocal_Parent = "this.subconOrderTableData"
            >


            </RequestFormVue>

            <SecondRequestForm
            :forSubconReq = "forSubconReq"
            :dataFromLocal_Parent = "this.subconOrderTableData"
            >


            </SecondRequestForm>
        </div>
    </v-container>
</template>

<script>
import { bus } from '../main'
import SecondRequestForm from '../components/SecondRequestForm.vue';
import editDialogVue from '../components/editDialog.vue'
import RequestFormVue from '../components/RequestForm.vue'
import axios from "axios";
import { message } from "ant-design-vue";
import Swal from "sweetalert2";
export default {
    components: {
    editDialogVue,
    RequestFormVue,
    SecondRequestForm
    },
  
    data() {
    return {
        
        secondRequestFormDialog:false, //test or form
        nameOfSubcon:'',
        urgentcodeNumber:[],
        receiveNotification:false,
        testSocket: [],
        kansenProposal:[],
        latesDataFromApi:[],
        subconOrderTableData:[],
        itemsPerPage: 100,
        requestFormDialog:false,
        editDialog: false,
        timestamp:'',
        allRequest:[],
        ongoingReq:[],
        newRequest:[],
        searchFirstRequest:'',
        searchSecondRequest:'',
        subconFirstRequest:[],
        subconSecondRequest:[],
        forSubconReq:{},

        firstRequestHeaders: [
            { text: "工事コード",               sortable : false,   value: "code_number",               align: "center",    class: "white--text", width:"200px"},
            { text: "上棟日",                   sortable : false,   value: "joutou",                    align: "center",    width:"120px",          class: "white--text",},
            { text: "受信日",                   sortable : false,   value: "firstReceive_date",              align: "center",    width:"120px",          class: "white--text",},
            { text: "発注期限",             sortable : false,   value: "lessjoutouDate",        align: "center",    width:"120px",          class: "white--text",},
            { text: "変更なしで依頼",       sortable : false,   value: "firstReq_with_changes",     align: "center",    width:"200px",},
            { text: "変更ありで依頼",   sortable : false,   value: "firstReq_without_changes",  align: "center",    width:"200px"},
            { text: "開始日",                    sortable : false,   value: "start_first_request",       align: "center",    width:"200px",          class: "white--text",},
            { text: "完了日",                 sortable : false,   value: "finish_first_request",      align: "center",    width:"200px",          class: "white--text",},
            { text: "状況",                   value: "order_status",align: "center",    width:"170px",          class: "white--text",},
        ],

        // firstRequestHeaders: [
        // { text: "Code number",              sortable : false,   value: "code_number",               align: "center",    class: "white--text", width:"150px"},
        // { text: "Joutou",                   sortable : false,   value: "joutou",                    align: "center",    width:"120px",          class: "white--text",},
        // { text: "Date Received",            sortable : false,   value: "firstReceive_date",              align: "center",    width:"120px",          class: "white--text",},
        // { text: "Due Date",                 sortable : false,   value: "lessjoutouDate",        align: "center",    width:"120px",          class: "white--text",},
        // { text: "Order with changes",       sortable : false,   value: "firstReq_with_changes",     align: "center",    width:"200px",},
        // { text: "Order without changes",    sortable : false,   value: "firstReq_without_changes",  align: "center",    width:"200px"},
        // { text: "Start",                    sortable : false,   value: "start_first_request",       align: "center",    width:"200px",          class: "white--text",},
        // { text: "Finished",                 sortable : false,   value: "finish_first_request",      align: "center",    width:"200px",          class: "white--text",},
        // { text: "Status",                   value: "order_status",align: "center",    width:"150px",          class: "white--text",},
        // ],

        secondRequestHeaders: [
        { text: "Code number",              sortable : false,   value: "code_number",           align: "center",    class: "white--text",   width:"360px"},
        { text: "Joutou",                   sortable : false,   value: "joutou",                align: "center",    width:"120px",  class: "white--text",},
        { text: "1st Request Finish Date",  sortable : false,   value: "finish_first_request",  align: "center",    width:"200px",  class: "white--text",},
        { text: "Order with changes",       sortable : false,   value: "",                      align: "center",    width:"200px",},
        { text: "Order without changes",    sortable : false,   value: "",                      align: "center",    width:"200px"},
        { text: "Start",                    sortable : false,   value: "",                      align: "center",    width:"200px",  class: "white--text",},
        { text: "Finished",                 sortable : false,   value: "",                      align: "center",    width:"200px",  class: "white--text",},
        { text: "Status",                   sortable : false,   value: "",                      align: "center",    width:"150px",  class: "white--text",},
        { text: "HRD DATA",                 sortable : false,   value: "",                      align: "center",    class: "white--text",},
        ],
    };
    },
    methods: {

    getOrderStatus(item) {
        if (item.order_status === 'New Request') {
            return '受付済';
        } else if (item.order_status === 'On-going') {
            return '手配中';
        } else if (item.order_status === 'Finished') {
            return '手配済み';
        } else if (item.order_status === 'Request Submitted') {
            return '依頼済み';
        } else if (item.order_status === 'No Request') {
            return '依頼不用';
        } else {
            return ''; // or return a default value for other cases
        }
    },

    viewProposal(item){
        
      
        const today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const dateToday = new Date(date).toISOString().split("T")[0];

            if(item.lessjoutouDate < dateToday && !item.isOverDue){
                    Swal.fire({
                    icon:'warning',
                    title: '注：',
                    //'Attention',
                    html: '上棟日までの納品に間に合う期限を過ぎていますが、依頼をしますか。.', 
                    //Are your sure you want to send request? Your request is already passed the Due date of order, delivery will be passed construction date.
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    showCancelButton: true,
                    confirmButtonText: '確認する',//'Confirm',
                    cancelButtonText : 'キャンセル',//'Cancel',
                    allowOutsideClick: () => {
                    const popup = this.$swal.getPopup()
                    popup.classList.remove('swal2-show')
                    return false
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                            this.forSubconReq = item
                            // bus.$emit('requestFormDialog') //Trial form
                            bus.$emit('secondRequestFormDialog')
                    }
                })
            }else{
                this.forSubconReq = item
                
                // bus.$emit('requestFormDialog') //trial form
                bus.$emit('secondRequestFormDialog')
            }
          
    },





    async loadSubconrequest(){
        const today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        
        // console.log(date);
        const headers = { 'x-api-key' : this.awsHeaders };
        let awsSubconData = await axios.get(`${this.$BaseUrl}/api/getSubcon1stRequestData`, { headers })
        // console.log(awsSubconData.data,'try 292');
   

        if(awsSubconData.status == 200){
                this.subconFirstRequest = awsSubconData.data
                    const combinedArray = this.subconOrderTableData.map(item1 => {
                            const matchingCodenumber = this.subconFirstRequest.find(item2 => item2.code_number === item1.code_number);
                            if (matchingCodenumber) {
                                return {...matchingCodenumber,...item1 };
                            }
                            return item1;
                    });
                    
                    if(this.account.role == 'System Admin'){
                        this.allRequest = combinedArray
                        const dateToday = new Date(date).toISOString().split("T")[0];
                
                        const urgentData = this.allRequest.filter(el => el.lessjoutouDate < dateToday)
                        const codeNumber = urgentData.map(element => {
                            return element.code_number
                        })

                        this.urgentcodeNumber = codeNumber

                    }else{
                        const filteredDataForTable = combinedArray.filter(el => el.subcon318 == this.account.username)
                        // console.log(filteredDataForTable,'line318');
                        this.allRequest = filteredDataForTable
                        const dateToday = new Date(date).toISOString().split("T")[0];
                
                            const urgentData = this.allRequest.filter(el => el.lessjoutouDate < dateToday)
                    
                            const codeNumber = urgentData.map(element => {
                                return element.code_number
                            })

                        this.urgentcodeNumber = codeNumber
            
                        this.nameOfSubcon = filteredDataForTable[0].subconName
                    }

                    const newRequest = this.allRequest.filter(el => !el.firstReq_without_changes && !el.firstReq_with_changes)
                    this.newRequest = newRequest
                    
                    const OngoingRequest = this.allRequest.filter(el => el.firstReq_without_changes || el.firstReq_with_changes)
                    this.ongoingReq = OngoingRequest
                
        }
        
        this.receiveNotification = false
    },

    NotifFornewSubconRequest(){
        this.allRequest = this.newRequest
        this.receiveNotification = true
    },
    NotifForOngoingRequest(){
        this.allRequest = this.ongoingReq
        this.receiveNotification = true
    },

    loadSubconOrderData(){
        axios({
                method: 'get',
                // url: `${this.$BaseUrl}/api/getTestDataofSubconOrder`, //for testing
                url: `${this.$BaseUrl}/api/getSubconOrder`, //for Actual
                headers: {
                'x-api-key': this.awsHeaders
                }
            }).then(res => {
                this.subconOrderTableData = res.data 
            })
    },
    
    sendNoRequest(item){
            // console.log(item);

            let objectToUpdate ={
                code_number : item.code_number
            }

            axios({
                            method: 'post',
                            url: `${this.$BaseUrl}/api/updateNoRequest`,
                            data:objectToUpdate,
                            headers: {
                                'x-api-key': this.awsHeaders
                            }
                        }).then(()=>{
                            message.success({
                                bottom: '100px',
                                duration: 2,
                                maxCount: 3,
                                text:'No request in order',
                                });
                                setTimeout(() => {
                                    location.reload()
                                }, 1000);
                        })
    }
    



    },// END OF METHOD




mounted() {
      
        this.loadSubconOrderData();
        
        

        bus.$on('LoadHomePage' , () => {
            this.loadSubconrequest()
        })

      
    },
    computed: {
          
        },

    watch: {
        subconOrderTableData(val){
            if(val.length){
                this.loadSubconrequest()

            }
        
        }
    },
};
</script>
<style>
@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
    }

    .blinking {
        animation: blink 0.5s infinite;
    }

    .classT tbody tr:hover {
        background-color: #ff8c8c;
    }
                                                                                
    .classT {
        border-left: 2px solid gray;
    }
                                                                                
    .theme--light.v-data-table
        > .v-data-table__wrapper
        > table
        > thead
        > tr:last-child
        > th {
            border-bottom: thin solid rgba(0, 0, 0, 0.12);
            background-color: #1F2833;
            font-size: 15px !important;
            color: white;
        }

    .classT, th,td,tr {
        border: 1px solid rgb(221, 140, 0);
    }

    .thStyle{
        background-color:rgb(26, 32, 38);
        font-size: 30px;
    }
</style>